import styled from 'styled-components'
import { Container, Grid, Typography } from '@material-ui/core'
import { Icon } from '../icon/icon'
import { rem } from '../../utils'

export const StyledCardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: ${props => props.theme.typography.pxToRem(25)};
  margin-bottom: ${props => props.theme.typography.pxToRem(14)};
  text-align: right;

  &:last-of-type {
    margin-bottom: 0;
  }

  > div:first-child {
    padding: ${props => props.theme.typography.pxToRem(6)} 0;
  }
`

export const StyledTitleText = styled(Typography)`
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: 500;
  line-height: 1.3;
  transition: margin-right 0.2s ease, color 0.2s ease;
`

export const StyledTitleCheveron = styled(Icon)`
  position: absolute;
  right: -20px;
  top: 0;
  margin-top: ${props => props.theme.typography.pxToRem(4)};
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => props.theme.typography.pxToRem(16)};
  transition: margin-right 0.2s ease;
`

export const StyledTitleTextContainer = styled(Grid)`
  position: relative;
  width: ${props => props.theme.typography.pxToRem(130)};
  overflow-wrap: break-word;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${props => props.theme.typography.pxToRem(5)};

  > a {
    text-decoration: none;
    overflow-wrap: normal;
  }

  &:hover {
    ${StyledTitleCheveron} {
      margin-right: -${props => props.theme.typography.pxToRem(10)};
    }

    ${StyledTitleText} {
      color: ${props => props.theme.palette.secondary.dark};
    }
  }
`

export const StyledOverviewTitle = styled.span`
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  transition: color 0.3s ease;
  text-decoration: underline;
`

export const StyledOverviewChevron = styled.div`
  width: ${props => rem(35, props.theme)};
  height: ${props => rem(35, props.theme)};
  margin-left: ${props => rem(25, props.theme)};

  svg {
    fill: currentColor;
  }
`

export const StyledOverviewContainer = styled.div`
  display: inline-block;
  margin-top: ${props => rem(26, props.theme)};

  a {
    display: flex;
    align-items: center;
    color: ${props => props.theme.palette.text.primary};
    transition: color 0.2s ease;
    white-space: nowrap;
  }

  &:hover {
    ${StyledOverviewTitle} {
      color: ${props => props.theme.palette.secondary.dark};
    }

    a {
      color: ${props => props.theme.palette.primary.main};
    }
  }
`

export const StyledHeaderContainer = styled(Container)`
  max-width: ${props => rem(1920, props.theme)};
  margin: auto;
  background-color: ${props => props.theme.palette.background.default};
  padding-left: 0;
  padding-right: 0;
  height: ${props => rem(83, props.theme)};

  > header {
    max-width: ${props => rem(1920, props.theme)};
    right: unset;
  }

  ${props => props.theme.breakpoints.up('xl')} {
    > header {
      max-width: 100%;
      right: 0;
    }
  }

  ${props => props.theme.breakpoints.up('sm')} {
    height: auto;
  }
`


