import React from 'react'
import Grid from '@material-ui/core/Grid'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { CreditUnionIcon, IconCodeType } from '../icon/CreditUnionIcon'
import {
  StyledCardContainer,
  StyledTitleCheveron,
  StyledTitleText,
  StyledTitleTextContainer,
} from './styledNavDropdownUtilityBar'
import { Link, LinkProps } from '../link/link'

export type UtilityBarCardProps = React.ComponentProps<typeof StyledCardContainer> & Pick<LinkProps, 'href'> & {
  title: string
  iconCode: IconCodeType
}

export const UtilityBarCard: React.FC<UtilityBarCardProps> = ({ title, iconCode, href, children, ...props }) => {
  return (
    <StyledCardContainer {...props}>
      <Grid item justifyContent="center" alignItems="center">
        <CreditUnionIcon height={47} width={47} iconCode={iconCode} />
      </Grid>
      <StyledTitleTextContainer>
        <Link href={href} passHref>
          <StyledTitleText>
            {title}
            <StyledTitleCheveron icon={faChevronRight} />
          </StyledTitleText>
        </Link>
      </StyledTitleTextContainer>
    </StyledCardContainer>
  )
}