import styled from 'styled-components'
import { Grid, Typography } from '@material-ui/core'
import { Icon } from '../icon/icon'

export const StyledTitleChevron = styled(Icon)<{$rotate?: boolean}>`
  color: ${props => props.theme.palette.primary.main};
  transform: ${props => props.$rotate ? 'rotate(180deg)' : 'none'};
  transform: translate(${props => props.theme.typography.pxToRem(10)}, ${props => props.theme.typography.pxToRem(1)});
  margin-top: ${props => props.theme.typography.pxToRem(4)};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  transition: transform 0.2s ease;
`

export const StyledTitleText = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(20)};
  font-weight: 500;
  transition: color 0.2s ease;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-left: 0;
  }

  &:hover {
    color: ${props => props.theme.palette.secondary.dark};

    ${StyledTitleChevron} {
      transform: translateX(${props => props.theme.typography.pxToRem(20)});
    }
  }
`

export const StyledTitleLinksWrapper = styled(Grid)`
  > a {
    text-decoration: none;
  }
`

export const StyledCardIconContainer = styled(Grid)`
  margin-bottom: ${props => props.theme.typography.pxToRem(26)};
`

export const StyledCardList = styled(Grid)`
  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => props.theme.typography.pxToRem(20)};
  }
`

export const StyledNavDropdownLinksContainer = styled(Grid)`
  column-gap: ${props => props.theme.typography.pxToRem(25)};

  > div:not(:last-child) {
    padding-right: ${props => props.theme.typography.pxToRem(34)};
  }

  > div:last-child {
    padding-left: ${props => props.theme.typography.pxToRem(68)};
  }
`