import React, { ComponentProps, useCallback, useContext, useEffect, useState } from 'react'
import { Container, Grid } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import styled from 'styled-components'
import { StyledNavItem } from '../PrimaryNavigation/NavItem'
import { PrimaryNavigationContext } from '../PrimaryNavigation/PrimaryNavigation'
import { rem } from '../../utils'

export type NavDropdownPanelProps = ComponentProps<typeof Grid> & {
  show?: boolean
}

export const NavDropdownPanel: React.FC<NavDropdownPanelProps> = ({ show=false, children, ...props }) => {
  const { secondary } = useContext(PrimaryNavigationContext)
  const [hasSticky, setHasNavSticky] = useState(false)

  const handleNavigation = useCallback((e) => {
    const window = e.currentTarget

    if (window) {
      if (!hasSticky && window.scrollY >= 80) {
        setHasNavSticky(true)
      }
      else if (hasSticky && window.scrollY < 80) {
        setHasNavSticky(false)
      }
    }
  }, [hasSticky])

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleNavigation)
    }

    return () => {
      if (window) {
        window.removeEventListener('scroll', handleNavigation)
      }
    }
  }, [handleNavigation])

  return (
    <StyledPanelWrapper $show={show} $hasSticky={hasSticky} $secondary={secondary} {...props}>
      <StyledPanelFullContainer>
        <Container maxWidth={'lg'}>
          <StyledDropdownPanel
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-end">
            { children }
          </StyledDropdownPanel>
        </Container>
      </StyledPanelFullContainer>
    </StyledPanelWrapper>
  )
}

const StyledPanelWrapper = styled.div<{ $show?: boolean; $secondary?: boolean; $hasSticky }>`
  visibility: ${props => props.$show ? 'visible' : 'hidden'};
  opacity: ${props => props.$show ? 1 : 0};
  will-change: opacity;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  top: ${props => rem(props.$secondary ? 104 : (props.$hasSticky? 74: 120), props.theme)};
  left: 0;
  right: 0;
  z-index: 9;
  filter: drop-shadow(0 ${props => props.theme.typography.pxToRem(2)} 0 ${alpha('#707070', 0.3)});

  ${StyledNavItem}:focus-within > & {
    visibility: visible;
    opacity: 1;
  }
`

export const StyledDropdownPanel = styled(Grid)`
  cursor: default;
  width: 100%;
  padding-top: ${props => props.theme.typography.pxToRem(60)};
  padding-bottom: ${props => props.theme.typography.pxToRem(100)};

  > .MuiGrid-item {
    padding: 0 !important;
    width: 100%;

    > .MuiGrid-align-items-xs-flex-start {
      gap: ${props => props.theme.typography.pxToRem(25)};
    }
  }

  > .MuiGrid-container {
    padding-top: ${props => props.theme.typography.pxToRem(48)};
  }
`

const StyledPanelFullContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #f8f8f8;

  ${props => props.theme.breakpoints.up('md')} {
    padding-bottom: ${props => props.theme.typography.pxToRem(45)};
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - ${props => props.theme.typography.pxToRem(45)}), 0 100%);
  }

  ${props => props.theme.breakpoints.up('lg')} {
    padding-bottom: ${props => props.theme.typography.pxToRem(90)};
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - ${props => props.theme.typography.pxToRem(90)}), 0 100%);
  }
`
